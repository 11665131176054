<template>
  <div class="Register">
    <div class="formBox">
      <div v-if="!pass">
        <h2>签收人注册</h2>
        <el-form
          ref="form"
          label-position="left"
          :model="form"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="销售方" prop="CompanyName">
            <el-autocomplete
              style="width: 100%"
              v-model="form.CompanyName"
              :fetch-suggestions="CompanySearchAsync"
              value-key="Title"
              placeholder="请输入销售方名称进行查询"
              @select="handleCompanySelect"
              :trigger-on-focus="false"
              @input="change"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="所属公司" prop="cCusName">
            <el-autocomplete
              style="width: 100%"
              v-model="form.cCusName"
              :fetch-suggestions="CustomerSearchAsync"
              value-key="cCusName"
              placeholder="请输入所属公司名称进行查询"
              @select="handleCustomerSelect"
              :trigger-on-focus="false"
              :disabled="!form.CompanyId"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="签收人" prop="Name">
            <el-input v-model="form.Name" placeholder="请输入签收人"></el-input>
          </el-form-item>
          <el-form-item label="签收电话" prop="Phone">
            <el-input
              v-model.number.trim="form.Phone"
              maxlength="11"
              placeholder="请输入签收电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <div style="display: flex">
              <el-input
                v-model="form.SmsCode"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                type="primary"
                style="margin-left: 5px"
                :disabled="disabled"
                @click="sendCode"
                >{{ !disabled ? "发送" : oneMinute + "S" }}</el-button
              >
            </div>
          </el-form-item>
        </el-form>
        <el-button
          @click="submit"
          style="width: 100%; margin-top: 20px"
          type="primary"
          >提 交</el-button
        >
      </div>
      <div v-else>
        <el-result icon="success" title="提示" subTitle="签收人录入已完成">
          <template slot="extra">
            <el-button type="primary" size="medium" @click="again"
              >再次录入</el-button
            >
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      form: {},
      pass: false,
      disabled: false,
      timer: null,
      oneMinute: 60,
      rules: {
        CompanyName: [
          { required: true, message: "请选择销售方", trigger: "change" },
        ],
        cCusName: [
          { required: true, message: "请选择所属公司", trigger: "change" },
        ],
        Name: [
          { required: true, message: "请输入签收人名称", trigger: "blur" },
        ],
        SmsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        Phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    sendCode() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        this.$http
          .post("/BO/Base_RegisterLinkMain/SaveRegister", this.form)
          .then((res) => {
            if (res.Success) {
              this.countdown()
              this.$message.success("验证码已经发送至您的手机,请注意查收");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    countdown() {
      clearInterval(this.timer);
      this.disabled = true;
      this.timer = setInterval(() => {
        this.oneMinute--;
        if (this.oneMinute <= 0) {
          clearInterval(this.timer);
          this.oneMinute = 60;
          this.disabled = false;
        }
      }, 1000);
    },
    change(e) {
      console.log(e);
      this.$set(this.form, "CompanyId", "");
    },
    CompanySearchAsync(queryString, cb) {
      clearTimeout(this.timeout);
      if (!queryString) {
        return;
      }
      this.timeout = setTimeout(() => {
        this.$http
          .post("/Base_Manage/Base_Company/GETWXCompayDataAsunc", {
            PageIndex: 1,
            PageRows: 9999,
            SortField: "Id",
            SortType: "desc",
            Search: {
              condition: queryString,
            },
          })
          .then((resJson) => {
            if (resJson.Success) {
              cb(resJson.Data);
            }
          });
      }, 1000);
    },
    handleCompanySelect(item) {
      console.log(item);
      this.$set(this.form, "CompanyId", item.Id);
    },
    CustomerSearchAsync(queryString, cb) {
      clearTimeout(this.timeout);
      if (!queryString) {
        return;
      }
      this.timeout = setTimeout(() => {
        this.$http
          .post("/Base_Manage/Base_Company/GetWXCustmerData", {
            PageIndex: 1,
            PageRows: 9999,
            SortField: "Id",
            SortType: "desc",
            Search: {
              condition: this.form.CompanyId,
              Keyword: queryString,
            },
          })
          .then((resJson) => {
            if (resJson.Success) {
              cb(resJson.Data);
            }
          });
      }, 1000);
    },
    handleCustomerSelect(item) {
      console.log(item);
      this.$set(this.form, "cCusName", item.cCusName);
      this.$set(this.form, "cCusCode", item.cCusCode);
      this.$set(this.form, "cCusId", item.Id);
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        this.$http
          .post("/BO/Base_RegisterLinkMain/SaveUserModel", this.form)
          .then((res) => {
            console.log(res);
            if (res.Success) {
              this.pass = true;
            }else{
              this.$message.error(res.Msg)
            }
          });
      });
    },
    again() {
      this.form = {};
      this.pass = false;
      this.timer = null
      this.oneMinute = 60
      this.disabled = false
    },
  },
};
</script>

<style lang="less" scoped>
.Register {
  padding: 20px;
  background-image: url("../../assets/PublicAccountBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  align-items: center;
  .formBox {
    background: #fff;
    padding: 10px;
    padding-top: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    flex: 1;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}
</style>